var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"disabled":!_vm.isActive,"icon":""},on:{"click":_vm.handleDeltaButtonClick}},[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1)]}}]),model:{value:(_vm.shown),callback:function ($$v) {_vm.shown=$$v},expression:"shown"}},[(_vm.shown)?_c('v-card',[_c('v-toolbar',{staticClass:"my-bar"},[_c('v-toolbar-title',[_vm._v("Просмотр изменений")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('div',{staticClass:"view",staticStyle:{"max-height":"calc(100vh - 64px)"}},[_c('div',{staticClass:"my-content",on:{"scroll":_vm.handleScroll}},[_c('v-form',{ref:"form",staticStyle:{"height":"100%"}},[_c('v-data-table',{staticStyle:{"height":"100%"},attrs:{"id":"logTable","items":_vm.items,"headers":_vm.headers,"items-per-page":-1,"item-key":"id","hide-default-footer":"","loading":_vm.loading,"loading-text":"Загрузка... Пожалуйста подождите","fixed-header":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state === 'Обновлено')?_c('span',[_c('strong',[_c('em',[_vm._v(_vm._s(item.state))])])]):_c('span',[_vm._v(" "+_vm._s(item.state)+" ")])]}}],null,false,2143480268)})],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }