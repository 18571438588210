<template>
  <v-form ref="form" v-model="valid" lazy-validation style="overflow: auto">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-autocomplete
            v-model="vm.editItem.insuredId"
            :items="ctx.src.insured.items"
            :label="'Застрахованный'"
            item-text="label"
            item-value="id"
            required
            clearable
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="vm.editItem.postingDate"
            label="Дата введения"
            type="date"
            required
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="vm.editItem.visitDate"
            label="Дата обращения"
            type="date"
            required
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.editItem.doctorId"
            :items="ctx.src.doctors.items"
            :label="'Доктор'"
            item-text="fullName"
            item-value="id"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.editItem.diagnosisId"
            :items="ctx.src.diagnosis.items"
            :label="'Диагноз1'"
            item-text="name"
            item-value="id"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="vm.editItem.diagnosisExt"
            label="Диагноз2"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.editItem.serviceId"
            :items="serviceItems"
            :label="'Услуга'"
            item-text="name"
            item-value="id"
            clearable
            required
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.editItem.serviceCategoryId"
            :items="ctx.src.categories.items"
            :label="'Категория услуги'"
            item-text="name"
            item-value="id"
            readonly
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="vm.editItem.quantity"
            label="Количество"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="vm.editItem.amount"
            label="Сумма"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="vm.editItem.discountPercent"
            label="Скидка, %"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="vm.editItem.discountedAmount"
            label="Сумма со скидкой"
            type="number"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import ctx from "@/ctx";
  import round2hunds from "../../utils/round2hunds";
  import fetch from "@/utils/fetch";

  export default {
    props: {
      vm: Object
    },
    data: () => ({
      ctx,
      requiredRules: [v => !!v || "Обязательное поле"],
      valid: false,
      ready: false,
      serviceItems: undefined,
    }),
    watch: {
      "vm.editItem": function() {
        this.$refs.form.validate();
      },
      "vm.editItem.serviceId": function(val) {
        if (!val) return (this.vm.editItem.serviceCategoryId = undefined);
        const service = ctx.src.services.get(val);


        if (service) {
          const amount = ctx.src.prices.find(service.id, this.vm.editItem.organizationId)?.amount;
          this.vm.editItem.amount = amount ? amount : this.vm.editItem.amount;
          this.vm.editItem.serviceCategoryId = service.categoryId
          this.vm.editItem.amount = service.amount ? service.amount : this.vm.editItem.amount;
        }
        else {this.vm.editItem.serviceCategoryId = undefined;}
      },
      "vm.editItem.amount": function(val) {
        if (!val) return (this.vm.editItem.discountedAmount = 0);
        if (
          !this.vm.editItem.discountPercent ||
          this.vm.editItem.discountPercent === 0
        )
          return (this.vm.editItem.discountedAmount = val);
        else
          return (this.vm.editItem.discountedAmount = round2hunds(
            val - (val * this.vm.editItem.discountPercent) / 100
          ));
      },
      "vm.editItem.discountPercent": function(val) {
        if (!val)
          return (this.vm.editItem.discountedAmount = this.vm.editItem.amount);
        else
          return (this.vm.editItem.discountedAmount = round2hunds(
            this.vm.editItem.amount - (this.vm.editItem.amount * val) / 100
          ));
      }
    },
    methods: {
      validate() {
        this.$refs.form.validate();
      }
      // async getServicesByOrganizationId() {
      //   const url =
      //     "/api/accounting/services/get-services-by-organization?id=" +
      //     this.vm.editItem.organizationId;

      //   let res = await fetch.get(url);
      //   if (!res) {
      //     return;
      //   }
      //   this.serviceItems = res;
      //   this.addCurrentServiceIfNotActive();
      // },

      // addCurrentServiceIfNotActive() {
      //
      //     this.serviceItems.filter(s => s.id === this.vm.editItem.serviceId)
      //       .length
      //   );
      //   if (
      //     this.serviceItems.filter(s => s.id === this.vm.editItem.serviceId)
      //       .length === 0
      //   ) {
      //     this.serviceItems.push(
      //       ctx.src.services.get(this.vm.editItem.serviceId)
      //     );
      //   }
      // }
      ,
      async getServices() {
        const insured = ctx.src.insured.get(this.vm.editItem.insuredId);
        return await fetch.get('/api/accounting/services/get-services-by-organization?id=' + this.vm.editItem.organizationId+ "&contractId=" + insured.contractId +
          "&insuredId=" + insured.id);
      }

    },

    async mounted() {
      this.vm.serviceDetail = this;


      this.getServices().then(result => {
        this.serviceItems = result;
      })



      this.validate();
    },

  };
</script>
