import fetch from "../../utils/fetch";
import ctx from "@/ctx";

const addCurrentServiceIfNotActive = (serviceArray = [], serviceId) => {
  if (serviceArray.filter(s => s.id === serviceId).length === 0) {
    serviceArray.push(ctx.src.services.get(serviceId));
  }
};

export const getServicesByOrganization = async (
  organizationId,
  serviceArray = [],
  serviceId,
  contractId,
  insuredId
) => {
  let res = await fetch.get(
    "/api/accounting/services/get-services-by-organization?id=" + organizationId + "&contractId=" + contractId + '&insuredId=' + insuredId
  );
  if (!res) return null;
  serviceArray = res;
  if (serviceId) {
    addCurrentServiceIfNotActive(serviceArray, serviceId);
  }
  return res;
};
