import ctx from '@/ctx'

export default function dictFilter(dict, key) {
  if (!dict || !key) return 'n/a'
  const items = ctx.src.dict.getDictItems(dict);
  const found = items.find(i => {
    return i.key === key
  })
  return found ? found.value : 'n/a'
}
