<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ item.state }}</td>
    <td>{{ item.date }}</td>
    <td>{{ item.userName }}</td>

    <td>
      {{ item.additional.number }}
    </td>
    <td>
      {{ organizationName }}
    </td>
    <td>{{ item.additional.description }}</td>
    <td>
      {{ state }}
    </td>
    <td>
      {{ item.additional.date }}
    </td>

    <td>
      {{ item.additional.postingDate }}
    </td>
    
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number
  },
  data: () => ({
    ctx
  }),
  computed: {
    state: function() {
      let stateEntry = ctx.src.dict["INS_ACCOUNT_STATUS"].get(
        this.item.additional.stateKey
      );
      return stateEntry ? stateEntry.value : "";
    },
    organizationName: function() {
      if (!this.item.additional.organizationId) return "";
      let result = ctx.src.org.get(this.item.additional.organizationId);
      return result ? result.fullName : "";
    }
  }
};
</script>
<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}
</style>
