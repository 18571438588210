<script>
export default {

  data: () => ({
    shown: false
  }),
  methods: {
    close() {
      this.shown = false
    },
    show() {
      this.shown = true;
    }
  }

}
</script>
