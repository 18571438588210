<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="!item || !item.id" icon @click="show">
        <v-icon>change_history</v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-toolbar class="my-bar">
        <v-toolbar-title>Просмотр изменений</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="view" style="max-height: calc(100vh - 64px)">
        <div class="my-content">
          <v-form ref="form" style="height: 100%">
            <v-simple-table fixed-header dense style="height: 100%">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Состояние</th>
                    <th>Дата</th>
                    <th>Пользователь</th>
                    <th class="text-left" style="min-width: 20vw">
                      Застрахованный
                    </th>
                    <th class="text-left">
                      Мед. учереждение
                    </th>
                    <th class="text-left">
                      Доктор
                    </th>
                    <th class="text-left">
                      Дата введения
                    </th>
                    <th class="text-left">
                      Дата обращения
                    </th>
                    <th class="text-left" style="min-width: 12vw">
                      Диагноз
                    </th>
                    <th class="text-left" style="min-width: 12vw">
                      Услуга
                    </th>
                    <th class="text-left" style="min-width: 12vw">
                      Статус
                    </th>
                    <th class="text-left">
                      Кол-во
                    </th>
                    <th class="text-left">
                      Сумма
                    </th>
                    <th class="text-left">
                      % Скидка
                    </th>
                    <th class="text-left">
                      Со скидкой
                    </th>
                    <th class="text-left">
                      Контрольный номер
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(item, i) in items" :key="i">
                  <ServiceHistoryItem
                    :item="item"
                    :index="i"
                  ></ServiceHistoryItem>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ctx from "@/ctx";
import ServiceHistoryItem from "./ServiceHistoryItem";
import fetch from "../../utils/fetch";
import vm2js from "../../utils/vm2js";
import js2vm from "../../utils/js2vm";
import datetimeLocalFilter from "../../filters/datetimeLocal.filter";

let Item = function(d) {
  let self = this;

  self.date2vm = function(val) {
    if (!val) return;
    return datetimeLocalFilter(val);
  };
  self.state2vm = function(val) {
    if (!val) return "Текущее";
    if ("CREATE" === val) return "Создано";
    if ("UPDATE" === val) return "Обновлено";
    return "";
  };

  js2vm(self, d);
};

export default {
  props: {
    item: Object
  },
  data: () => ({
    ctx,
    items: [],
    dialog: false,
    loading: false
  }),
  methods: {
    async show() {
      this.items = [];
      this.loading = true;
      this.dialog = true;
      const res = await fetch.get(
        "/api/accounting/invoices/get-service-log?entryId=" + this.item.id
      );
      this.items.push(
        new Item({
          state: undefined,
          additional: vm2js(this.item)
        })
      );
      if (res) res.forEach(r => this.items.push(new Item(r)));
      this.loading = false;
    }
  },
  mounted() {
    this.items = [];
  },
  components: {
    ServiceHistoryItem
  }
};
</script>
