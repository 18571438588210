<template>
  <div class="view-wrapper this-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-dialog
          v-if="rightFilter('w')"
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="add">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card v-if="dialog">
            <v-toolbar class="my-bar">
              <v-toolbar-title>{{
                "Добавление строк счета: ".concat(
                vm.item.organization.fullName,
                ", № ",
                vm.item.number,
                " от ",
                vm.item.postingDate4List
                )
                }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <ServiceAdd :vm="this"></ServiceAdd>
          </v-card>
        </v-dialog>
        <v-btn
          v-if="rightFilter('d')"
          icon
          :disabled="!editItem"
          @click="dialogDelete = true"
        >
          <v-icon>remove</v-icon>
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
            >Вы точно хотите удалить услугу?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDelete = false">Отмена</v-btn>
              <v-btn color="primary" text @click="deleteConfirm">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
        <ServiceHistory :item="editItem"></ServiceHistory>
        <v-text-field
          v-model="amount"
          label="Итого"
          hide-details
          outlined
          dense
          readonly
          style="max-width: 168px; margin: 0 2px"
        ></v-text-field>
        <v-text-field
          v-model="discountedAmount"
          label="Итого со скидкой"
          hide-details
          outlined
          dense
          readonly
          style="max-width: 168px; margin: 0 2px"
        ></v-text-field>
        <v-btn icon color="primary" :loading="loading" @click="exp">
          <v-icon>cloud_download</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        :item-class="itemColor"
        @click:row="rowClick"
      >
        <template v-slot:item.stateKey="{ item }">
          <v-icon v-if="item.stateKey === 'E'" color="green">done</v-icon>
          <v-icon v-else-if="item.stateKey === 'R'" color="blue"
          >arrow_right</v-icon
          >
          <v-icon v-else color="orange">arrow_left</v-icon>
        </template>
      </v-data-table>
    </div>
    <div v-if="editItem" class="view">
      <v-toolbar class="my-bar">
<!--        <v-btn
          v-if="rightFilter('w')"
          icon
          :disabled="!serviceDetail || !serviceDetail.valid"
          @click="save"
        >
          <v-icon>save</v-icon>
        </v-btn>-->
        <SaveButton
        :additional-rules="!serviceDetail || !serviceDetail.valid"
        :handler="save"
        >

        </SaveButton>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <ServiceDetail :vm="this"></ServiceDetail>
    </div>
    <CheckLimit :vm="this"></CheckLimit>
  </div>
</template>

<script>
  import fetch from "@/utils/fetch";
  import vm2js from "@/utils/vm2js";
  import js2vm from "@/utils/js2vm";
  import ctx from "@/ctx";
  import dateLocalFilter from "../../filters/dateLocal.filter";
  import dateIsoFilter from "../../filters/dateIso.filter";
  import ServiceDetail from "./ServiceDetail";
  import ServiceAdd from "./ServiceAdd";
  import moment from "moment";
  import CheckLimit from "../balance/Check";
  import rightFilter from "../../filters/right.filter";
  import ServiceHistory from "./ServiceHistory";
  import round2hunds from "../../utils/round2hunds";
  import SaveButton from "@/components/ButtonFacade";

  let Item = function(d) {
    let self = this;

    d.organization = d.organizationId;
    d.doctor = d.doctorId;
    d.diagnosis = d.diagnosisId;
    d.service = d.serviceId;
    d.serviceCategory = d.serviceCategoryId;
    d.visitDate4List = d.visitDate;
    d.postingDate4List = d.postingDate;
    d.insured = d.insuredId;
    d.diagnosisExt = d.diagnosisExt ? d.diagnosisExt : "";

    self.insured2vm = function(val) {
      if (!val) return {};
      return ctx.src.insured.get(val);
    };
    self.organization2vm = function(val) {
      if (!val) return {};
      return ctx.src.org.first(val);
    };
    self.doctor2vm = function(val) {
      if (!val) return {};
      return ctx.src.doctors.get(val);
    };

    self.diagnosis2vm = function(val) {
      if (!val) return {};
      return ctx.src.diagnosis.get(val);
    };

    self.visitDate4List2vm = function(val) {
      return dateLocalFilter(val);
    };
    self.postingDate4List2vm = function(val) {
      return dateLocalFilter(val);
    };
    self.visitDate2vm = function(val) {
      return dateIsoFilter(val);
    };
    self.postingDate2vm = function(val) {
      return dateIsoFilter(val);
    };

    self.service2vm = function(val) {
      if (!val) return {};
      return ctx.src.services.get(val);
    };
    self.serviceCategory2vm = function(val) {
      if (!val) return {};
      return ctx.src.categories.get(val);
    };

    js2vm(self, d);
  };

  export default {
    props: {
      vm: Object
    },
    data: () => ({
      ctx,
      rightFilter,
      serviceDetail: undefined,
      currentPage: 0,
      totalPages: 0,
      loading: false,
      selected: [],
      headers: [
        { text: "", value: "stateKey" },

        {
          text: "Номер",
          sortable: false,
          value: "index"
        },
        {
          text: "Застрахованный",
          value: "insured.label"
        },
        { text: "Доктор", value: "doctor.fullName" },
        { text: "Дата введения", sortable: false, value: "postingDate4List" },
        { text: "Дата обращения", sortable: true, value: "visitDate4List" },
        { text: "Диагноз", sortable: true, value: "diagnosis.name" },
        { text: "Услуга", sortable: true, value: "service.name" },
        { text: "Категория", sortable: true, value: "serviceCategory.name" },
        { text: "Кол-во", sortable: true, value: "quantity" },
        { text: "% Скидка", sortable: true, value: "discountPercent" },
        { text: "Сумма", sortable: true, value: "amount" },
        { text: "Со скидкой", sortable: true, value: "discountedAmount" },
        { text: "Контрольный номер", sortable: false, value: "checkNumber" }
      ],
      items: [],
      item: undefined,
      dialog: false,
      dialogDelete: false,
      editItem: undefined,
      searchText: undefined
    }),
    watch: {
      "vm.item": function(val) {
        this.selected = [];
        this.items = [];
        if (val) {
          this.fetchItems();
        }
      },
      selected: function(val) {
        if (val.length === 0) this.editItem = undefined;
        else {
          this.editItem = new Item(vm2js(val[0]));
        }
      }
    },
    methods: {
      itemColor(item) {
        if (!item.insured || !item.insured.startDate || !item.insured.endDate)
          return "";
        if (
          moment(item.visitDate) < moment(item.insured.startDate) ||
          moment(item.visitDate) > moment(item.insured.endDate)
        )
          return "item-red";
        return "";
      },
      add() {
        this.close();
        this.dialog = true;
      },
      async deleteConfirm() {
        const res = await fetch.post(
          "/api/accounting/invoices/delete-service?id=" + this.editItem.id
        );
        if (res) {
          const index = this.items.indexOf(this.selected[0]);
          this.items.splice(index, 1);
          this.selected = [];
          this.dialogDelete = false;
        }
      },
      async save() {
        let arr = [];
        let item = vm2js(this.editItem);
        item.id = undefined;
        item.discountedAmount =
          item.discountedAmount - this.selected[0].discountedAmount;
        item.quantity = item.quantity - this.selected[0].quantity;
        arr.push(item);

        this.limitCheck.setItem(item);

        this.limitCheck.check(
          item.insuredId,
          item.serviceCategoryId,
          item.discountedAmount,
          item.quantity,
          item.id
        )
        const res = await fetch.post(
          "/api/accounting/invoices/save-service",
          vm2js(this.editItem)
        );
        if (res) {
          this.fetchItems();
          this.close();
        }
        this.sortByCardPeriod();
      },
      close() {
        this.selected = [];
      },
      async fetchItems() {
        this.items = [];

        if (!this.vm.item.id) return;
        this.loading = true;
        await this.getItems();
        this.loading = false;

        this.sortByCardPeriod();
      },
      async getItems() {
        let res = await fetch.get(
          "/api/accounting/invoices/get-all-services-by-invoice?invoiceId=" +
          this.vm.item.id
        );


        if (res) {

          res.forEach(r => {
            this.items.push(new Item(r));
          });
        }


        if (res.first) {
          return res.totalPages;
        }
        this.sortByCardPeriod();
      },
      rowClick(obj, row) {
        if (!row.isSelected) row.select(obj);
        else this.selected = [];
      },
      async exp() {
        this.loading = true;
        await this.vm.exp();
        this.loading = false;
      },
      sortByCardPeriod() {
        this.items = this.items.sort((a, b) => {
          return (moment(a.visitDate).isBefore(a.insured.startDate) || moment(a.visitDate).isAfter(a.insured.endDate)) ? -1 : !!b;
        })
      }
    },
    computed: {
      amount: function() {
        let sum = 0;
        this.items.forEach(i => {
          sum = round2hunds(sum + i.amount);
        });
        return sum;
      },
      discountedAmount: function() {
        let sum = 0;
        this.items.forEach(i => {
          sum = round2hunds(sum + i.discountedAmount);

        });

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.vm.item.amount = sum;
        return sum;
      }
    },
    mounted() {
      this.vm.serviceList = this;

      this.fetchItems();


    },
    components: {
      SaveButton,
      ServiceAdd,
      ServiceDetail,
      CheckLimit,
      ServiceHistory
    }
  };
</script>

<style lang="scss" scoped>
  .this-wrapper {
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .this-wrapper::v-deep .v-data-table__wrapper {
    > table {
      counter-reset: section;
      tbody tr td:nth-child(3)::before {
        counter-increment: section;
        content: counter(section);
      }
    }
  }
</style>
