<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ item.state }}</td>
    <td>{{ item.date }}</td>
    <td>{{ item.userName }}</td>

    <td>
      <v-autocomplete
        v-model="item.additional.insuredId"
        :items="ctx.src.insured.items"
        item-text="label"
        item-value="id"
        readonly
      >
      </v-autocomplete>
    </td>
    <td>
      <v-autocomplete
        v-model="item.additional.organizationId"
        :items="ctx.src.org.items"
        item-text="fullName"
        item-value="id"
        readonly
      >
      </v-autocomplete>
    </td>
    <td>
      <v-autocomplete
        v-model="item.additional.doctorId"
        :items="ctx.src.doctors.items"
        item-text="fullName"
        item-value="id"
        readonly
      >
      </v-autocomplete>
    </td>
    <td>
      <v-text-field
        v-model="item.additional.postingDate"
        type="date"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.additional.visitDate"
        type="date"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-autocomplete
        v-model="item.additional.diagnosisId"
        :items="ctx.src.diagnosis.items"
        item-text="name"
        item-value="id"
        readonly
      >
      </v-autocomplete>
    </td>
    <td>
      <v-autocomplete
        v-model="item.additional.serviceId"
        :items="ctx.src.services.items"
        item-text="name"
        item-value="id"
        readonly
      >
      </v-autocomplete>
    </td>
    <td>
      <v-text-field
      v-model="serviceStatus"
      readonly
      >

      </v-text-field>

    </td>
    <td>
      <v-text-field
        v-model="item.additional.quantity"
        type="number"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.additional.amount"
        type="number"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.additional.discountPercent"
        type="number"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.additional.discountedAmount"
        type="number"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.additional.checkNumber"
        readonly
      ></v-text-field>
    </td>
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number
  },
  data: () => ({
    ctx
  }),
  computed: {
    serviceStatus: function()  {
      if(this.item.additional.stateKey) {
        return ctx.src.dict['INS_ACCOUNT_LINE_STATUS'].get(this.item.additional.stateKey).value;
      }
      else return undefined;
    }
  }

};
</script>

<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}
</style>
