import ctx from '@/ctx'
import {mapLogCommonData} from "@/components/logs/logsCommon";
import dateLocalFilter from "@/filters/dateLocal.filter";

//Структура таблицы логов, передается в prop 'headers' в v-data-table
export const logHeaders = [

  {
    text: "Застрахованный",
    value: "insuredName"
  },
  {
    text: "Мед. учереждение",
    value: "organizationName"
  },
  {
    text: "Доктор",
    value: "doctorName"
  },
  {
    text: "Дата введения",
    value: "postingDate"
  },

  {
    text: "Дата обращения",
    value: "visitDate"
  },
  {
    text: "Диагноз",
    value: "diagnosis"
  },
  {
    text: "Услуга",
    value: "serviceName"
  },

  {
    text: "Статус",
    value: "serviceStatus"
  },
  {
    text: "Кол-во",
    value: "quantity"
  },
  {
    text: "Сумма",
    value: "amount"
  },
  {
    text: "% Скидка",
    value: "discountPercent"
  },
  {
    text: "Со скидкой",
    value: "discountedAmount"
  },
  {
    text: "Контрольный номер",
    value: "checkNumber"
  },




]



//Функция преобразования отдельной записи логов
export function mapLogItem(item) {
  const additional = item.additional;
  const result = mapLogCommonData(item)
  result.insuredName = item.insuredName
  result.serviceName = item.serviceName

  if(!additional) return result
  if(additional.organizationId)
    result.organizationName = ctx.src.org.items.find(o => o.id === additional.organizationId).fullName
  if(additional.doctorId)
    result.doctorName = ctx.src.doctors.items.find(d => d.id === additional.doctorId).fullName
  if(additional.postingDate)
    result.postingDate = dateLocalFilter(additional.postingDate)
  if(additional.visitDate)
    result.visitDate = dateLocalFilter(additional.visitDate)
  if(additional.diagnosisId)
    result.diagnosis = ctx.src.diagnosis.items.find(i => i.id === additional.diagnosisId).name
  if(additional.stateKey)
    result.serviceStatus = ctx.src.dict['INS_ACCOUNT_LINE_STATUS'].get(additional.stateKey).value;
  if(additional.quantity)
    result.quantity = additional.quantity
  if(additional.amount)
    result.amount = additional.amount
  if(additional.discountPercent)
    result.discountPercent = additional.discountPercent
  if(additional.discountedAmount)
    result.discountedAmount = additional.discountedAmount
  if(additional.checkNumber)
    result.checkNumber = additional.checkNumber


  return result

}
