<template>
  <div class="view-wrapper">
    <div class="view fsh-3">
      <v-toolbar class="my-bar">
        <v-dialog v-model="dialog" max-width="500px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>filter_alt</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Фильтр</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="filter.from"
                      label="С"
                      type="date"
                      required
                      @keydown.enter="fetchItems"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="filter.to"
                      label="По"
                      type="date"
                      required
                      @keydown.enter="fetchItems"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filter.organizationId"
                      :items="ctx.src.org.activeByType(['3', '4'])"
                      :label="'Организация'"
                      item-text="fullName"
                      item-value="id"
                      clearable
                      @keydown.enter="fetchItems"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filter.doctorId"
                      :items="ctx.src.doctors.items"
                      :label="'Врач'"
                      item-text="fullName"
                      item-value="id"
                      clearable
                      @keydown.enter="fetchItems"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="filter.number"
                      label="Номер"
                      clearable
                      @keydown.enter="fetchItems"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filter.stateKey"
                      :items="ctx.src.dict['INS_ACCOUNT_STATUS'].items"
                      :label="'Статус'"
                      item-text="value"
                      item-value="key"
                      clearable
                      @keydown.enter="fetchItems"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn text @click="resetFilter">
                Сбросить
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">
                Отмена
              </v-btn>
              <v-btn color="primary" text @click="fetchItems">
                Ок
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-dialog
          v-if="rightFilter('d')"
          v-model="dialogDelete"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :disabled="!item || !item.id" v-bind="attrs" v-on="on">
              <v-icon>remove</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline"
              >Вы точно хотите удалить счет?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDelete = false">Отмена</v-btn>
              <v-btn color="primary" text @click="rem">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        @click:row="rowClick"
      >
        <template v-slot:item.index="{item, index}">
          <span>{{index + 1}}</span>
        </template>
        <template v-slot:item.contractDate="{ item }">
          <span>{{ item.contractDate | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.startDate="{ item }">
          <span>{{ item.startDate | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.endDate="{ item }">
          <span>{{ item.endDate | dateLocalFilter }}</span>
        </template>
      </v-data-table>
    </div>
    <div v-if="item" class="view fg-3">
      <v-tabs class="toolbar">
        <v-tab>Детали</v-tab>
        <v-tab v-if="item.id">Услуги</v-tab>
        <v-toolbar class="my-bar">
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tab-item>
          <div
            class="view"
            style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden"
          >
            <v-toolbar class="my-bar">
<!--              <v-btn v-if="rightFilter('w')" icon @click="save">
                <v-icon>save</v-icon>
              </v-btn>-->
              <SaveButton
              :handler="save"
              >

              </SaveButton>
              <v-spacer></v-spacer>
              <v-btn icon color="primary" :loading="loading" @click="exp">
                <v-icon>cloud_download</v-icon>
              </v-btn>

              <InvoiceHistory :item="item"></InvoiceHistory>
              <div id="logView">
              <LogView apiUrl="/api/accounting/invoices/get-service-log-by-invoice"
                       :is-active="!!item && !!item.id"
                       :entity-headers="logSchemeHeaders"
                       icon="$vuetify.icons.invoiceServiceLog"
                       :query-parameters="`&invoiceId=${item.id}`"

              ></LogView>
              </div>
            </v-toolbar>
            <Detail :vm="this"></Detail>
          </div>
        </v-tab-item>
        <v-tab-item>
          <ServicesList :vm="this"></ServicesList>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import ctx from "@/ctx";
import fetch from "../utils/fetch";
import Detail from "../components/invoices/Detail";
import js2vm from "../utils/js2vm";
import ServicesList from "../components/invoices/ServicesList";
import dateIsoFilter from "../filters/dateIso.filter";
import dateLocalFilter from "../filters/dateLocal.filter";
import dictFilter from "../filters/dict.filter";
import vm2js from "../utils/vm2js";
import rightFilter from "../filters/right.filter";
import InvoiceHistory from "../components/invoices/InvoiceHistory.vue";
import SaveButton from "@/components/ButtonFacade";
import LogView from "@/components/logs/LogView";
import {logHeaders} from '@/components/logs/log-table-configs/invoice-service';


let Filter = function() {
  let self = this;

  let from = new Date();
  from.setDate(from.getDate() - 5);
  self.from = dateIsoFilter(from);
  self.to = dateIsoFilter(new Date());

  self.organizationId = undefined;
  self.doctorId = undefined;
  self.number = undefined;
  self.stateKey = undefined;
};

let Item = function(d) {
  let self = this;

  d.organization = d.organizationId;
  d.date4List = d.date;
  d.postingDate4List = d.postingDate;
  d.state = d.stateKey;

  d.date = d.date ? d.date : new Date();
  d.postingDate = d.postingDate ? d.postingDate : new Date();


  self.organization2vm = function(val) {
    if (!val) return {};
    return ctx.src.org.first(val);
  };

  self.date4List2vm = function(val) {
    return dateLocalFilter(val);
  };
  self.postingDate4List2vm = function(val) {
    return dateLocalFilter(val);
  };
  self.date2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.postingDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.state2vm = function(val) {
    return dictFilter("INS_ACCOUNT_STATUS", val);
  };

  js2vm(self, d);

};

export default {
  data: () => ({
    ctx,
    rightFilter,
    selected: [],
    loading: false,
    headers: [
      {
        text: "№",
        align: "start",
        sortable: false,
        value: "index"
      },

      {
        text: "Номер",
        align: "start",
        sortable: true,
        value: "number"
      },
      {
        text: "Мед. учреждение",
        sortable: true,
        value: "organization.fullName"
      },
      { text: "Статус", sortable: true, value: "state" },
      { text: "Дата счета", sortable: false, value: "date4List" },
      { text: "Дата проведения", sortable: false, value: "postingDate4List" },
      { text: "Сумма", sortable: false, value: "amount", align: "end" },
      {
        text: "Сумма со скидкой",
        sortable: false,
        value: "discountedAmount",
        align: "end"
      }
          ],
    items: [],
    item: undefined,
    filter: new Filter(),
    dialog: false,
    dialogDelete: false,
    logSchemeHeaders: logHeaders
  }),
  watch: {
    selected: function(val) {
      if (val.length === 0) this.item = undefined;
      else this.item = val[0];
    }
  },
  methods: {
    resetFilter() {
      this.filter = new Filter();
    },
    add() {
      this.close();
      this.selected.push(new Item({}));
    },
    async save() {
      const res = await fetch.post(
        "/api/accounting/invoices/save",
        vm2js(this.item)
      );
      if (res) {
        if (this.item.id) {
          let found = this.items.find(i => {
            return i.id === res;
          });
          if (found) Object.assign(found, this.item);
        } else {
          this.item.id = res;
          this.items.unshift(new Item(vm2js(this.item)));
        }
        this.close();
      }
    },
    async rem() {
      const res = await fetch.post(
        "/api/accounting/invoices/delete?id=" + this.item.id
      );
      if (res) {
        this.close();
        this.items.splice(this.item, 1);
        this.dialogDelete = false;
      }
    },
    close() {
      this.selected = [];
    },
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    },
    async fetchItems() {
      this.loading = true;
      this.selected = [];
      this.items = [];
      this.dialog = false;
      let res = await fetch.post(
        "/api/accounting/invoices/get-list",
        vm2js(this.filter)
      );
      if (res) {
        res.forEach(element => {
          this.items.push(new Item(element));
        });
      }
      this.loading = false;
    },
    async exp() {
      this.loading = true;
      let data = vm2js(this.item);
      data.label = "".concat(
        data.organization.fullName,
        ", № ",
        data.number,
        " от ",
        data.postingDate4List
      );
      const res = await fetch.post("/api/accounting/invoices/export", data);
      if (res) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(await res.blob);
        link.download = res.filename;
        link.click();
      }
      this.loading = false;
    }
  },
  mounted() {
    this.fetchItems();
  },
  components: {
    LogView,
    SaveButton,
    Detail,
    ServicesList,
    InvoiceHistory
  }
};
</script>
