import datetimeLocalFilter from "@/filters/datetimeLocal.filter";

const stateLocalizationDictionary = {
  UPDATE: "Обновлено",
  DELETE: "Удалено",
  CREATE: "Создано"
};

// Создать и вернуть отображение лога, которое содержит общие для каждого вида логов данные
const mapLogCommonData = (logItem) => {
  const result = {}
  result.state = stateLocalizationDictionary[logItem.state];
  result.userName = logItem.userName
  result.date = datetimeLocalFilter(logItem.date)
  return result
}

export {mapLogCommonData}
