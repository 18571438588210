<script>
import fetch from "@/utils/fetch";

export default {
  props: {
    queryParameters: String
  },
  data: () => ({
    url: '',
    items: [],
    currentPage: 0,
    totalPages: 0,

  }),

  methods: {
    async performFetch() {
      this.loading = true;
      const result = await fetch.get(this.url + `?page=${this.currentPage}${this.queryParameters}`)

      if(!result) return
      this.writePaginationInfo(result.totalPages)
      result.content.forEach(resultItem => this.items.push(this.mapItem(resultItem)))

      this.loading = false;

    },


    writePaginationInfo(totalPages) {
      this.totalPages = totalPages
    },

    mapItem(item) {

    }

  }
}
</script>
