<template>
  <span :style="style">{{insured.label}}</span>
</template>

<script>

import insuredCardStatusEnum from "@/utils/staticData/insuredCardStatusEnum";

export default {
  name: "InsuredAutocompleteItem",
  props: {
    insured: {
      type: Object,
      default: undefined
    },

  },
  data() {
    return {
      styles: {
        expired: {
          color: 'orange'
        },
        notStarted: {
          color: 'blue'
        },
        default: {}
      }
    }
  },
  computed: {
    style() {
      let resultStyle = this.styles.default;
      switch (this.insured.cardStatus) {
        case insuredCardStatusEnum.NOT_STARTED:
          resultStyle = this.styles.notStarted;
          break;
        case insuredCardStatusEnum.EXPIRED:
          resultStyle = this.styles.expired;
          break;
      }
      return resultStyle;
    }
  }
}
</script>

<style scoped>



</style>
