<template>
    <v-dialog

      v-model="shown"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"

    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn :disabled="!isActive" icon @click="handleDeltaButtonClick">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>

      <v-card v-if="shown">
        <v-toolbar class="my-bar">
          <v-toolbar-title>Просмотр изменений</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="view" style="max-height: calc(100vh - 64px)">
          <div class="my-content" @scroll="handleScroll">
            <v-form ref="form" style="height: 100%">
              <v-data-table

                id="logTable"

                :items="items"
                :headers="headers"
                :items-per-page="-1"
                item-key="id"
                hide-default-footer
                :loading="loading"
                loading-text="Загрузка... Пожалуйста подождите"
                fixed-header
                style="height: 100%"

              >
                <template v-slot:item.index="{index}">
                  <span>{{ index + 1 }}</span>
                </template>
                <template v-slot:item.state="{item}">

                  <span v-if="item.state === 'Обновлено'">
                    <strong>
                      <em>{{item.state}}</em>
                    </strong>
                  </span>
                  <span v-else>
                    {{item.state}}
                  </span>
                </template>
              </v-data-table>
            </v-form>
          </div>

        </div>
      </v-card>

    </v-dialog>
</template>

<script>

const stateLocalizationDictionary = {
  UPDATE: "Обновлено",
  DELETE: "Удалено",
  CREATE: "Создано"
};

import PaginatedFetchMixin from "@/components/mixins/PaginatedFetchMixin";
import ClosableMixin from "@/components/mixins/ClosableMixin";
import {mapLogItem} from '@/components/logs/log-table-configs/invoice-service'

export default {
  mixins: [PaginatedFetchMixin, ClosableMixin],
  name: "LogView",
  props: {
    apiUrl: String,
    isActive: Boolean,
    entityHeaders: Array,
    icon: {
      default: 'change_history'
    }

  },
  data: () => ({
    headers: [
      {
        text: "№",
        align: "start",
        sortable: false,
        value: "index"
      },
      {
        text: "Состояние",
        value: "state"
      },
      {
        text: "Дата",
        value: "date"
      },
      {
        text: "Пользователь",
        value: "userName"
      }

    ],
    loading: false,
  }),

  watch: {
    shown: function (val) {
      if (!val) this.items = []
    }
  },


  methods: {
    async handleDeltaButtonClick() {
      await this.performFetch();
      this.show();

    },

    mapItem(item) {
      return mapLogItem(item)
    },

    initState() {
      this.url = this.apiUrl
      this.headers = this.headers.concat(this.entityHeaders)
    },
    handleScroll(e) {
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.95 && this.totalPages - 1 > this.currentPage
        && this.shown
      ) {
        console.log("SCROLL EVENT")
        this.currentPage++;
        this.performFetch()


      }
    },


  },

  mounted() {
    this.initState()


  }

}
</script>


<style scoped>

</style>
