<template>
  <v-form ref="form" v-model="valid" lazy-validation style="overflow: auto">
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="vm.item.number"
            label="Номер"
            :rules="requiredRules"
            required
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="vm.item.organizationId"
            :items="ctx.src.org.activeByType(['3', '4'])"
            :label="'Медицинское учреждение'"
            item-text="fullName"
            item-value="id"
            :rules="requiredRules"
            required
            clearable
            @change="changeOrganization"
          ></v-autocomplete>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="vm.item.stateKey"
            :items="ctx.src.dict['INS_ACCOUNT_STATUS'].items"
            :label="'Статус'"
            item-text="value"
            item-value="key"
            :rules="requiredRules"
            required
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="vm.item.description"
            label="Описание"
            clearable
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="vm.item.date"
            label="Дата сч. ф."
            type="date"
            :rules="requiredRules"
            required
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="vm.item.postingDate"
            label="Дата проведения"
            type="date"
            :rules="requiredRules"
            required
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    vm: Object
  },
  data: () => ({
    ctx,
    valid: false,
    requiredRules: [v => !!v || "Обязательное поле"]
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    changeOrganization(value) {
      this.vm.item.organizationId = value;

    }
  },
  mounted() {
    this.vm.detail = this;
    this.validate();



  }
};
</script>
